<template>
  <div>
    <b-sidebar
      id="sidebar-add-new-engagment"
      ref="sidebar-engagment"
      sidebar-class="sidebar-lg"
      bg-variant="white"
      backdrop-variant="dark"
      backdrop
      :no-close-on-backdrop="true"
      shadow
      no-header
      right
      @change="(val) => $emit('update:is-event-handler-sidebar-active', val)"
    >

      <template #default="{ hide }">
        <!-- Header -->
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h5 class="mb-0">
            {{ form.type === '' ? $t('formInteractionEventHandler.titleSidebarAdd') : $t('formInteractionEventHandler.titleSidebarUpdate') }}
          </h5>
          <div>
            <feather-icon
              v-if="form.type === 'Modifica Interazione'"
              icon="TrashIcon"
              class="cursor-pointer"
              @click="$emit('remove-event'); resetForm(); hide();"
            />
            <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              size="16"
              @click="resetForm(); hide()"
            />
          </div>
        </div>
        <b-form
          ref="form-engagment"
          @submit.prevent="saveSubmit"
        >
          <b-container>
            <h4 class="mt-2 mb-1">
              {{ $t('formInteractionEventHandler.informationInteraction') }}
            </h4>
            <b-row>
              <b-col
                cols="12"
                md="7"
              >
                <b-form-group
                  :label="$t('formInteractionEventHandler.date')"
                  label-for="egangment-date-interaction"
                  label-class="font-weight-bolder"
                >
                  <b-form-datepicker
                    id="egangment-date-interaction"
                    v-model="form.dateInteraction"
                    class="mb-1"
                    v-bind="labelsDate[locale] || {}"
                    :locale="locale"
                  />
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="5"
              >
                <b-form-group
                  :label="$t('formInteractionEventHandler.time')"
                  label-for="egangment-time-interaction"
                  label-class="font-weight-bolder"
                >
                  <b-form-timepicker
                    id="egangment-time-interaction"
                    v-model="form.timeInteractoin"
                    v-bind="labelsTime[locale] || {}"
                    :locale="locale"
                  />

                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
              >

                <b-form-group
                  :label="$t('formInteractionEventHandler.typeInteraction')"
                  label-for="egangment-interaction-selected"
                  label-class="font-weight-bolder"
                >
                  <v-select
                    id="egangment-interaction-selected"
                    v-model="form.interactionSelected"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="title"
                    :options="form.optionInteractionFrom"
                  />
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
              >
                <b-form-group
                  :label="$t('formInteractionEventHandler.reasonInteraction')"
                  label-for="egangment-reasonIntercation-selected"
                  label-class="font-weight-bolder"
                >
                  <v-select
                    id="egangment-reasonIntercation-selected"
                    v-model="form.reasonInteractionSelected"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="title"
                    :options="form.optionReasonInteraction"
                  />
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
              >
                <b-form-group
                  :label="$t('formInteractionEventHandler.description')"
                  label-for="egangment-description"
                  label-class="font-weight-bolder"
                >
                  <b-form-textarea
                    id="textarea-default"
                    v-model="form.description"
                    placeholder="Textarea"
                    rows="3"
                  />
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
              >
                <b-form-group
                  :label="$t('formInteractionEventHandler.person')"
                  label-for="egangment-filteredOptions-person"
                  label-class="font-weight-bolder"
                >
                  <b-col
                    class="d-flex m-0 p-0"
                  >
                    <vue-autosuggest
                      :suggestions="form.filteredOptions"
                      :limit="10"
                      :input-props="{id:'autosuggest__input',class:'form-control', placeholder:'Cerca Persona'}"
                      @selected="getSuggestionPerson"
                      @input="onInputChangePeople"
                    >
                      <template slot-scope="{suggestion}">
                        <span class="my-suggestion-item">{{ suggestion.item.name }}</span>
                      </template>
                    </vue-autosuggest>

                    <b-button
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      v-b-modal.quick-add-person-modal
                      variant="outline-primary"
                    >
                      <feather-icon
                        icon="UserPlusIcon"
                      />
                    </b-button>
                    <QuickAddPerson @emitQuickAddPerson="pushQuickPerson" />
                  </b-col>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
              >
                <b-form-group
                  :label="$t('formInteractionEventHandler.patient')"
                  label-for="egangment-filteredOptions-patient"
                  label-class="font-weight-bolder"
                >
                  <vue-autosuggest
                    :suggestions="form.filteredOptions"
                    :limit="10"
                    :input-props="{id:'autosuggest__input',class:'form-control', placeholder:'Cerca Paziente'}"
                    @selected="getSuggestionPatient"
                    @input="onInputChangePatient"
                  >
                    <template slot-scope="{suggestion}">
                      <span class="my-suggestion-item">{{ suggestion.item.name }}</span>
                    </template>
                  </vue-autosuggest>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
              >
                <b-form-group
                  :label="$t('formInteractionEventHandler.doctor')"
                  label-for="egangment-filteredOptions-doctor"
                  label-class="font-weight-bolder"
                >
                  <v-select
                    id="egangment-filteredOptions-doctor"
                    v-model="form.selectedDoctor"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="title"
                    :options="form.optionDoctor"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </b-container>
          <b-container>
            <h4 class="mt-2 mb-1">
              {{ $t('formInteractionEventHandler.accountingOperations') }}
            </h4>
            <b-row>
              <b-col
                cols="12"
                md="6"
              >
                <b-form-group
                  :label="$t('formInteractionEventHandler.cashTransactions')"
                  label-for="egangment-selected-cashOperation"
                  label-class="font-weight-bolder"
                >
                  <v-select
                    id="egangment-selected-cashOperation"
                    v-model="form.selectedCashOperations"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="title"
                    :clearable="false"
                    :options="form.cashOperationOption"
                    @input="setselectedCashOperations"
                  />
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
              >
                <b-form-group
                  :label="$t('formInteractionEventHandler.detailsCashTransactions')"
                  label-for="egangment-details-cashOperation"
                  label-class="font-weight-bolder"
                >
                  <v-select
                    id="egangment-details-cashOperation"
                    v-model="form.selectedDetailsCashOperation"
                    :value="form.selectedMethodPayment"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="title"
                    :clearable="false"
                    :disabled="form.selectedCashOperations.title === 'No cassa' || form.selectedCashOperations.title === ''"
                    :options="form.detailsCashOperationOption"
                  />
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="5"
              >
                <b-form-group
                  :label="$t('formInteractionEventHandler.methodPayment')"
                  label-for="egangment-details-cashOperation"
                  label-class="font-weight-bolder"
                >
                  <v-select
                    ref="method-p"
                    v-model="form.selectedMethodPayment"
                    :value="form.selectedMethodPayment"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="title"
                    :clearable="false"
                    :disabled="form.selectedCashOperations.title === 'No cassa' || form.selectedCashOperations.title === ''"
                    :options="form.metodPaymentOptions"
                  />
                </b-form-group>
              </b-col>
              <b-col class="d-flex justify-content-end p-0">
                <b-col
                  cols="12"
                  md="6"
                >
                  <b-form-group
                    :label="$t('formInteractionEventHandler.totalAmount')"
                    label-for="inputImport"
                    label-class="font-weight-bolder"
                  >

                    <b-form-input
                      id="inputImport"
                      v-model="form.totalToPay"
                      type="number"
                      :disabled="form.selectedCashOperations.title === 'No cassa' || form.selectedCashOperations.title === ''"
                      placeholder="Inserisci importo"
                    />
                  </b-form-group>
                </b-col>
              </b-col>
              <b-col
                cols="12"
                class="d-flex justify-content-end mt-1"
              >
                <b-button type="submit">
                  {{ $t('formInteractionEventHandler.saveButton') }}
                </b-button>
              </b-col>
            </b-row>
            selectedMethodPayment {{ form.selectedMethodPayment }}
            selectedDetailsCashOperation {{ form.selectedDetailsCashOperation }}
            selectedCashOperations {{ form.selectedCashOperations }}

            dateInteraction {{ form.dateInteraction }}
            timeInteractoin {{ form.timeInteractoin }}
            interactionSelected {{ form.interactionSelected }}
            description {{ form.description }}
            typeInteractionSelected {{ form.reasonInteractionSelected }}
            selectedPerson {{ form.selectedPerson }}
            selectedPatient {{ form.selectedPatient }}
            selectedDoctor {{ form.selectedDoctor }}
          </b-container>
        </b-form>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import {
  BForm, BSidebar, BContainer, BRow, BCol, BFormGroup, BFormDatepicker, BFormTimepicker, BFormTextarea, BFormInput, VBModal, BButton,
} from 'bootstrap-vue'
// import flatPickr from 'vue-flatpickr-component'
import vSelect from 'vue-select'

// import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import Ripple from 'vue-ripple-directive'
import { required, email, url } from '@validations'
import { VueAutosuggest } from 'vue-autosuggest'
import QuickAddPerson from '@/views/pages/modal/QuickAddPerson.vue'

// import formValidation from '@core/comp-functions/forms/form-validation'
// import { ref, toRefs } from '@vue/composition-api'
// import useColllaboratorsEventHandler from './useColllaboratorsEventHandler'

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BSidebar,
    BFormGroup,
    BFormInput,
    vSelect,
    BFormDatepicker,
    BFormTextarea,
    BFormTimepicker,
    VueAutosuggest,
    QuickAddPerson,
    BContainer,
    BForm,
    /* BFormCheckbox,
    BFormRadio,
    BContainer,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckboxGroup,
    BFormSelect,

    FormWizard,
    TabContent,
    WizardButton,

    ValidationProvider,
    ValidationObserver, */
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  /* model: {
    prop: 'isEventHandlerSidebarActive',
    event: 'update:is-event-handler-sidebar-active',
  }, */
  props: {
  },
  data() {
    return {
      required,
      email,
      url,
      // label locale
      labelsDate: {
        it: {
          labelPrevDecade: 'Decennio Precedente',
          labelPrevYear: 'Anno Precendente',
          labelPrevMonth: 'Mese Precedente',
          labelCurrentMonth: 'Mese Attuale',
          labelNextMonth: 'Mese Successivo',
          labelNextYear: 'Anno Successivo',
          labelNextDecade: 'Decennio Successivo',
          labelToday: 'Oggi',
          labelSelected: 'Data Selezionata',
          labelNoDateSelected: 'Selezionata Data',
          labelCalendar: 'Calendario',
          labelNav: 'Navigazione del calendario',
          labelHelp: 'Usa i tasti freccia per navigare nel calendario',
        },
      },
      labelsTime: {
        it: {
          labelHours: 'Ore',
          labelMinutes: 'Minuti',
          labelSeconds: 'Secondi',
          labelIncrement: 'Incrementa',
          labelDecrement: 'Decrementa',
          labelSelected: 'Selezionata',
          labelNoTimeSelected: 'Seleziona Orario',
          labelCloseButton: 'Chiudi',
        },
      },
      formReset: {
        type: '',
        dateInteraction: null,
        timeInteractoin: null,
        interactionSelected: { title: '' },
        optionInteractionFrom: [{ title: 'Telefonata' }, { title: 'In Sede' }, { title: 'Sito Web' }, { title: 'Referal' }, { title: 'Campagna Ads' }, { title: 'Social' }],
        reasonInteractionSelected: { title: '' },
        optionReasonInteraction: [{ title: 'Prestazione' }, { title: 'Informazioni' }, { title: 'Appuntamento' }, { title: 'Fornitore' }, { title: 'Rappresentanza' }, { title: 'Altro...' }],
        description: '',
        filteredOptions: [],
        selectedDoctor: { title: '', value: '' },
        optionDoctor: [{ title: 'Pino', value: 'dott1' }, { title: 'Gino', value: 'dott2' }, { title: 'Pippo', value: 'dott3' }, { title: 'Tino', value: 'dott4' }],
        selectedPerson: {},
        selectedPatient: {},
        selectedCashOperations: { title: '' },
        cashOperationOption: [{ title: 'No cassa' }, { title: 'Entrata' }, { title: 'Uscita' }],
        detailsCashOperationOption: [{ title: 'Versamento' }, { title: 'Sospseso di cassa' }, { title: 'Nota Rientro sospeso di cassa' }, { title: 'Prelievo' }],
        selectedDetailsCashOperation: { title: '' },
        selectedMethodPayment: { title: '' },
        metodPaymentOptions: [{ title: 'Contanti' }, { title: 'Bancomant' }, { title: 'Carta di Credito' }, { title: 'Assegno' }, { title: 'Cambiali' }],
        totalToPay: 0,
      },
      form: {
        type: '',
        dateInteraction: null,
        timeInteractoin: null,
        interactionSelected: { title: '' },
        optionInteractionFrom: [{ title: 'Telefonata' }, { title: 'In Sede' }, { title: 'Sito Web' }, { title: 'Referal' }, { title: 'Campagna Ads' }, { title: 'Social' }],
        reasonInteractionSelected: { title: '' },
        optionReasonInteraction: [{ title: 'Prestazione' }, { title: 'Informazioni' }, { title: 'Appuntamento' }, { title: 'Fornitore' }, { title: 'Rappresentanza' }, { title: 'Altro...' }],
        description: '',
        filteredOptions: [],
        selectedDoctor: { title: '', value: '' },
        optionDoctor: [{ title: 'Pino', value: 'dott1' }, { title: 'Gino', value: 'dott2' }, { title: 'Pippo', value: 'dott3' }, { title: 'Tino', value: 'dott4' }],
        selectedPerson: {},
        selectedPatient: {},
        selectedCashOperations: { title: '' },
        cashOperationOption: [{ title: 'No cassa' }, { title: 'Entrata' }, { title: 'Uscita' }],
        detailsCashOperationOption: [{ title: 'Versamento' }, { title: 'Sospseso di cassa' }, { title: 'Nota Rientro sospeso di cassa' }, { title: 'Prelievo' }],
        selectedDetailsCashOperation: { title: '' },
        selectedMethodPayment: { title: '' },
        metodPaymentOptions: [{ title: 'Contanti' }, { title: 'Bancomant' }, { title: 'Carta di Credito' }, { title: 'Assegno' }, { title: 'Cambiali' }],
        totalToPay: 0,
      },

      // prova
      filteredOptions: [],

      limit: 10,
      // operazioni di cassa
      cashOperationOption: [{ title: 'No cassa' }, { title: 'Entrata' }, { title: 'Uscita' }],
      selectedCashOperations: { title: 'No cassa' },

      // dettaglio operazione
      detailsCashOperationOption: [{ title: 'Versamento' }, { title: 'Sospseso di cassa' }, { title: 'Nota Rientro sospeso di cassa' }, { title: 'Prelievo' }],
      selectedDetailsCashOperation: { title: '' },

      // metodo di pagamento
      metodPaymentOptions: [{ title: 'Contanti' }, { title: 'Bancomant' }, { title: 'Carta di Credito' }, { title: 'Assegno' }, { title: 'Cambiali' }],
      selectedMethodPayment: { title: '' },
      //  importo da pagare
      totalToPay: 0,
      selectedMethodPayment1: '',
    }
  },
  computed: {
    // locale
    locale() {
      return this.$i18n.locale
    },
  },
  watch: {
    form() {
      // console.log('form')
      if (this.form.selectedCashOperations.title === '' || this.form.selectedCashOperations.title === 'No Cassa') {
        this.form.selectedDetailsCashOperation = { title: '' }
        this.form.selectedMethodPayment = { title: '' }
        this.form.totalToPay = 0
      }
      // console.log('sono dentro watch')
      // if (this.typeSelected !== 'Person') {
      // console.log(this.$refs['wizard-collaborators-manage'])
      // this.$refs['wizard-collaborators-manage'].activateAll()
      // }
    },
    deep: true,
  },
  created() {
    this.peopleSuggest = [
      {
        // developers
        developers: [
          {
            // img: require('@/assets/images/portrait/small/avatar-s-4.jpg'),
            name: 'Rena Brant',
            email: 'nephrod@preany.co.uk',
            time: '21/05/2019',
          },
          {
            // img: require('@/assets/images/portrait/small/avatar-s-2.jpg'),
            name: 'Mariano Packard',
            email: 'seek@sparaxis.org',
            time: '14/01/2018',
          },
          {
            // img: require('@/assets/images/portrait/small/avatar-s-24.jpg'),
            name: 'Risa Montufar',
            email: 'vagary@unblist.org',
            time: '10/08/2019',
          },
          {
            // img: require('@/assets/images/portrait/small/avatar-s-15.jpg'),
            name: 'Maragaret Cimo',
            email: 'designed@insanely.net',
            time: '01/12/2019',
          },
          {
            // img: require('@/assets/images/portrait/small/avatar-s-7.jpg'),
            name: 'Jona Prattis',
            email: 'unwieldable@unblist.org',
            time: '21/05/2019',
          },
          {
            // img: require('@/assets/images/portrait/small/avatar-s-5.jpg'),
            name: 'Edmond Chicon',
            email: 'museist@anaphyte.co.uk',
            time: '15/11/2019',
          },
          {
            // img: require('@/assets/images/portrait/small/avatar-s-25.jpg'),
            name: 'Abbey Darden',
            email: 'astema@defectively.co.uk',
            time: '07/05/2019',
          },
        ],

        // designers
        designers: [
          {
            // img: require('@/assets/images/portrait/small/avatar-s-10.jpg'),
            name: 'Seema Moallankamp',
            email: 'fernando@storkish.co.uk',
            time: '13/08/2017',
          },
          {
            // img: require('@/assets/images/portrait/small/avatar-s-2.jpg'),
            name: 'Charleen Warmington',
            email: 'furphy@cannibal.net',
            time: '11/08/1891',
          },
          {
            //  img: require('@/assets/images/portrait/small/avatar-s-25.jpg'),
            name: 'Geri Linch',
            email: 'insignia@markab.org',
            time: '18/01/2015',
          },
          {
            // img: require('@/assets/images/portrait/small/avatar-s-23.jpg'),
            name: 'Shellie Muster',
            email: 'maxillary@equalize.co.uk',
            time: '26/07/2019',
          },
          {
            // img: require('@/assets/images/portrait/small/avatar-s-20.jpg'),
            name: 'Jesenia Vanbramer',
            email: 'hypotony@phonetist.net',
            time: '12/09/2017',
          },
          {
            // img: require('@/assets/images/portrait/small/avatar-s-23.jpg'),
            name: 'Mardell Channey',
            email: 'peseta@myrica.com',
            time: '11/11/2019',
          },
          {
            // img: require('@/assets/images/portrait/small/avatar-s-24.jpg'),
            name: 'Lilliana Stroman',
            email: 'lilliana@stroman.com',
            time: '12/11/2020',
          },
        ],
      },
    ]
  },
  methods: {
    resetNoCash() {
      this.selectedMethodPayment.title = ''
    },
    pushQuickPerson(person) {
      this.peopleSuggest[0].developers.unshift(person)
    },
    getSuggestionPerson(text) {
      this.form.selectedPerson = text.item
    },
    getSuggestionPatient(text) {
      this.form.selectedPatient = text.item
    },
    onInputChangePeople(text) {
      if (text === '' || text === undefined) {
        return
      }

      /* Full control over filtering. Maybe fetch from API?! Up to you!!! */
      const filteredDevelopers = this.peopleSuggest[0].developers.filter(item => item.name.toLowerCase().indexOf(text.toLowerCase()) > -1).slice(0, this.limit)
      const filteredDesigner = this.peopleSuggest[0].designers.filter(item => item.name.toLowerCase().indexOf(text.toLowerCase()) > -1).slice(0, this.limit)
      const filteredData = filteredDevelopers.concat(filteredDesigner)
      this.form.filteredOptions = [{
        data: filteredData,
      }]
    },
    onInputChangePatient(text) {
      if (text === '' || text === undefined) {
        return
      }

      /* Full control over filtering. Maybe fetch from API?! Up to you!!! */
      const filteredDevelopers = this.peopleSuggest[0].developers.filter(item => item.name.toLowerCase().indexOf(text.toLowerCase()) > -1).slice(0, this.limit)
      const filteredDesigner = this.peopleSuggest[0].designers.filter(item => item.name.toLowerCase().indexOf(text.toLowerCase()) > -1).slice(0, this.limit)
      const filteredData = filteredDevelopers.concat(filteredDesigner)
      this.form.filteredOptions = [{
        data: filteredData,
      }]
    },
    saveSubmit() {
      // this.$refs['form-engagment'].reset()
    },
    resetForm() {
      // console.log(this.$refs['wizard-collaborators-manage'])
      // console.log('prima', this.form)
      // console.log('prima formReset', this.formReset)
      // console.log('dopo', this.form)
      // console.log('dopo formReset', this.formReset)
      setTimeout(() => {
        this.form = JSON.parse(JSON.stringify(this.formReset))
      }, 350)
    },
    setFormToEdit(engagment, title) {
      // console.log('engagment', title)
      this.form.type = title
      this.form.dateInteraction = engagment.date
      this.form.timeInteractoin = null
      this.form.interactionSelected = { title: engagment.typeInteractionFrom }
      this.form.reasonInteractionSelected = { title: engagment.typeInteraction }
      this.form.optionReasonInteraction = [{ title: 'Prestazione' }, { title: 'Informazioni' }, { title: 'Appuntamento' }, { title: 'Fornitore' }, { title: 'Rappresentanza' }, { title: 'Altro...' }]
      this.form.description = engagment.description
      this.form.filteredOptions = []
      this.form.selectedDoctor = { title: engagment.person, value: '' }
      this.form.optionDoctor = [{ title: 'Pino', value: 'dott1' }, { title: 'Gino', value: 'dott2' }, { title: 'Pippo', value: 'dott3' }, { title: 'Tino', value: 'dott4' }]
      this.form.selectedPerson = {}
      this.form.selectedPatient = {}
    },
    setselectedCashOperations(cashOperationObject) {
      // console.log('evt', cashOperationObject)
      if (cashOperationObject.title === 'No cassa') {
        this.form.selectedMethodPayment.title = ''
        this.form.selectedDetailsCashOperation.title = ''
        this.form.totalToPay = 0
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-autosuggest.scss';
</style>
<style scoped>

</style>

<template>
  <div>
    <b-row
      no-gutters
      class="d-flex justify-content-center"
    >
      <b-col
        cols="12"
        md="6"
        lg="4"
        xl="3"
      >
        <SupplierTypePatients :data="typePatients" />
      </b-col>
      <b-col
        cols="12"
        md="6"
        lg="4"
        xl="3"
      >
        <SupplierTypeVisit :data="typeAnalisys" />
      </b-col>
      <b-col
        cols="12"
        md="6"
        lg="4"
        xl="3"
      >
        <SupplierVisitStats :data="typePatients" />
      </b-col>
      <b-col
        cols="12"
        md="6"
        lg="3"
      >
        <SupplierVaccinationStats :data="typePatients" />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
// import CustomerGeneralStats from '../charts/CustomerGeneralStats.vue'
import SupplierTypePatients from '../charts/SupplierTypePatients.vue'
import SupplierTypeVisit from '../charts/SupplierTypeVisit.vue'
import SupplierVisitStats from '../charts/SupplierVisitStats.vue'
import SupplierVaccinationStats from '../charts/SupplierVaccinationStats.vue'

export default {
  components: {
    BRow,
    BCol,
    SupplierTypePatients,
    SupplierTypeVisit,
    SupplierVisitStats,
    SupplierVaccinationStats,
  },
  data() {
    return {
      statisticsItems: [
        {
          icon: 'TrendingUpIcon',
          color: 'light-primary',
          title: '4',
          subtitle: 'Pazienti',
          customClass: 'mb-2 mb-xl-0',
        },
        {
          icon: 'UserIcon',
          color: 'light-info',
          title: '15',
          subtitle: 'Visite',
          customClass: 'mb-2 mb-xl-0',
        },
        {
          icon: 'BoxIcon',
          color: 'light-danger',
          title: '10',
          subtitle: 'Analisi',
          customClass: 'mb-2 mb-sm-0',
        },
        {
          icon: 'DollarSignIcon',
          color: 'light-success',
          title: '6',
          subtitle: 'Vaccinazioni',
          customClass: '',
        },
      ],
      typePatients: {
        series: [53, 16, 31],
      },
      typeAnalisys: {
        series: [60, 20, 20],
      },
    }
  },
}
</script>

<style>

</style>

<template>
  <b-card>
    <b-tabs
      vertical
      content-class="col-12 col-md-9 mt-1 mt-md-0"
      pills
      nav-wrapper-class="col-md-2 col-12"
      nav-class="nav-left"
    >

      <!-- general tab -->
      <b-tab active>

        <!-- title -->
        <template #title>
          <feather-icon
            icon="UserIcon"
            size="18"
            class="mr-50"
          />
          <span class="font-weight-bold">Preventivi</span>
        </template>

        <ContabilitySupplier />
      </b-tab>
      <!--/ general tab -->

      <!-- change password tab -->
      <b-tab>

        <!-- title -->
        <template #title>
          <feather-icon
            icon="LockIcon"
            size="18"
            class="mr-50"
          />
          <span class="font-weight-bold">Fatture</span>
        </template>
        <FiscalSupplier />
      </b-tab>
      <!--/ change password tab -->

      <!-- info -->
      <b-tab>

        <!-- title -->
        <template #title>
          <feather-icon
            icon="InfoIcon"
            size="18"
            class="mr-50"
          />
          <span class="font-weight-bold">Sospesi</span>
        </template>
        <GenericSupplier />
      </b-tab>

    </b-tabs>
  </b-card>
</template>

<script>
import { BTabs, BTab, BCard } from 'bootstrap-vue'
import ContabilitySupplier from '../documents/ContabilitySupplier.vue'
import FiscalSupplier from '../documents/FiscalSupplier.vue'
import GenericSupplier from '../documents/GenericSupplier.vue'

export default {
  components: {
    BCard,
    BTabs,
    BTab,
    ContabilitySupplier,
    FiscalSupplier,
    GenericSupplier,
  },
  data() {
    return {
      options: {},
    }
  },
  created() {
    // console.log('customer documents creata')
  },
  /*   beforeCreate() {
    this.$http.get('/account-setting/data').then(res => { this.options = res.data })
  }, */
}
</script>

<template>
  <b-card>
    <b-row
      class="d-fkex justify-content-between"
    >
      <b-col
        cols="12"
        lg="4"
      >
        <h4>
          Dati Personali
        </h4>
        <table class="mt-2 mt-xl-0 w-100 tableInfo">
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="UserIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Nome</span>
            </th>
            <td class="pb-50">
              {{ userData.fullName }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="CheckIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Cognome</span>
            </th>
            <td class="pb-50 text-capitalize">
              {{ userData.username }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="StarIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Data di Nascita</span>
            </th>
            <td class="pb-50 text-capitalize">
              {{ userData.dateBorn }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="FlagIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Luogo di Nascita</span>
            </th>
            <td class="pb-50">
              {{ userData.cityBorn }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="FlagIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Codice Fiscale</span>
            </th>
            <td class="pb-50">
              {{ userData.fiscalCode }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="FlagIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Indirizzo Residenza</span>
            </th>
            <td class="pb-50">
              {{ userData.streetAddress }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="FlagIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Città</span>
            </th>
            <td class="pb-50">
              {{ userData.cityAddress }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="FlagIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Telefono Personale</span>
            </th>
            <td class="pb-50">
              {{ userData.phonePersonal }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="FlagIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Telefono Casa</span>
            </th>
            <td class="pb-50">
              {{ userData.phoneHome }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="FlagIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Telefono Ufficio</span>
            </th>
            <td class="pb-50">
              {{ userData.phoneOffice }}
            </td>
          </tr>
        </table>
      </b-col>
      <b-col
        cols="12"
        lg="4"
      >
        <h4>
          Dati Fiscali
        </h4>

        <table class="mt-2 mt-xl-0 w-100 tableInfo">
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="UserIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Nome/Regione Sociale</span>
            </th>
            <td class="pb-50">
              {{ userData.fullName }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="CheckIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">P.IVA</span>
            </th>
            <td class="pb-50 text-capitalize">
              {{ userData.fiscalCodeOrIva }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="StarIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Indirizzo Fatturazione</span>
            </th>
            <td class="pb-50 text-capitalize">
              {{ userData.addressInvoices }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="FlagIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Indirizzo Spedizione</span>
            </th>
            <td class="pb-50">
              {{ userData.addressShipping }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="FlagIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">PEC</span>
            </th>
            <td class="pb-50">
              {{ userData.email }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="FlagIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Indirizzo Residenza</span>
            </th>
            <td class="pb-50">
              {{ userData.streetAddress }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="FlagIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Città</span>
            </th>
            <td class="pb-50">
              {{ userData.cityAddress }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="FlagIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Telefono Ufficio</span>
            </th>
            <td class="pb-50">
              {{ userData.phoneOffice }}
            </td>
          </tr>

        </table>
      </b-col>
      <b-col
        cols="12"
        lg="4"
      >
        <h4>
          Privacy / GDPR
        </h4>
        <div>
          <b-col
            cols="12"
          >
            <table class="mt-2 mt-xl-0 w-100 tableInfo">
              <tr>
                <th class="pb-50">
                  <feather-icon
                    icon="UserIcon"
                    class="mr-75"
                  />
                  <span class="font-weight-bold">Privacy</span>
                </th>
                <td class="pb-50">
                  {{ userData.privacyAccepted }}
                </td>
              </tr>
              <tr>
                <th class="pb-50">
                  <feather-icon
                    icon="CheckIcon"
                    class="mr-75"
                  />
                  <span class="font-weight-bold">GDRP</span>
                </th>
                <td class="pb-50 text-capitalize">
                  {{ userData.gdpr }}
                </td>
              </tr>
            </table>
          </b-col>
        </div>
      </b-col>
      <b-col
        cols="12"
        lg="4"
      >
        <h4>
          Privacy / GDPR
        </h4>
        <div>
          <b-col
            cols="12"
          >
            <table class="mt-2 mt-xl-0 w-100 tableInfo">
              <tr>
                <th class="pb-50">
                  <feather-icon
                    icon="UserIcon"
                    class="mr-75"
                  />
                  <span class="font-weight-bold">Privacy</span>
                </th>
                <td class="pb-50">
                  {{ userData.privacyAccepted }}
                </td>
              </tr>
              <tr>
                <th class="pb-50">
                  <feather-icon
                    icon="CheckIcon"
                    class="mr-75"
                  />
                  <span class="font-weight-bold">GDRP</span>
                </th>
                <td class="pb-50 text-capitalize">
                  {{ userData.gdpr }}
                </td>
              </tr>
            </table>
          </b-col>
        </div>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol,
} from 'bootstrap-vue'

/* import CustomerVaccinationStats from '../charts/CustomerVaccinationStats.vue'
import CustomerTypePatients from '../charts/CustomerTypePatients.vue'
import CustomerTypeVisit from '../charts/CustomerTypeVisit.vue'
import CustomerVisitStats from '../charts/CustomerVisitStats.vue'
import CustomerRecoveryStats from '../charts/CustomerRecoveryStats.vue'
import CustomerSurgeryStats from '../charts/CustomerSurgeryStats.vue'
 */
export default {
  components: {
    BRow,
    BCol,
    BCard,
  },
  data() {
    return {
      userData: {
      // eslint-disable-next-line global-require
        coverImg: require('@/assets/images/profile/user-uploads/timeline.jpg'),
        emailMain: 'kocrevy0@thetimes.co.uk',
        avatar: '/img/9.3fd39137.png',
        type: 'Person',
        fullName: 'Fornitore Topolino',
        username: 'Kocrevy',
        status: 'active',
        country: 'italy',
        email: 'kocrevy0@thetimes.co.uk',
        dateBorn: '10/12/1990',
        cityBorn: 'Catania',
        streetAddress: 'Via entea',
        cityAddress: 'Mascalucia',
        addressInvoices: 'Via etnea 120',
        addressShipping: 'Via etnea 123 A',
        phonePersonal: '3489502145',
        phoneHome: '095988510',
        phoneOffice: '095895623',
        fiscalCodeOrIva: 'QPFPDP74S29C030E',
        fiscalCode: 'QPFPDP74S29C030E',
        privacyAccepted: 'Accettato',
        gdpr: 'Accettato',
        role: 'Editor',
        id: 1,
        originalIndex: 0,
        contact: '3387415011',
        rating: 4,
      },
      statisticsItems: [
        {
          icon: 'TrendingUpIcon',
          color: 'light-primary',
          title: '4',
          subtitle: 'Pazienti',
          customClass: 'mb-2 mb-xl-0',
        },
        {
          icon: 'UserIcon',
          color: 'light-info',
          title: '15',
          subtitle: 'Visite',
          customClass: 'mb-2 mb-xl-0',
        },
        {
          icon: 'BoxIcon',
          color: 'light-danger',
          title: '10',
          subtitle: 'Analisi',
          customClass: 'mb-2 mb-sm-0',
        },
        {
          icon: 'DollarSignIcon',
          color: 'light-success',
          title: '6',
          subtitle: 'Vaccinazioni',
          customClass: '',
        },
      ],
      typePatients: {
        series: [53, 16, 31],
      },
      typeAnalisys: {
        series: [60, 20, 20],
      },
    }
  },
  created() {
    // console.log('infocustomer creata')
  },
}
</script>

<style>

</style>

<template>
  <b-modal
    id="quick-add-person-modal"
    ref="quick-add-person-modal"
    ok-only
    :ok-title="$t('quickAddPersonModal.titleHeader')"
    centered
    title="Aggiungi Persona"
    @ok="addPerson"
  >
    <b-row>
      <b-col>
        <b-form-group>
          <h5>{{ $t('quickAddPersonModal.name') }}</h5>
          <b-form-input
            id="quick-add-person-name"
            v-model="name"
            placeholder="Inserisci nome"
          />
        </b-form-group>
        <b-form-group>
          <h5>{{ $t('quickAddPersonModal.surname') }}</h5>
          <b-form-input
            id="quick-add-person-surname"
            v-model="surname"
            placeholder="Inserisci cognome"
          />
        </b-form-group>
        <b-form-group>
          <h5>{{ $t('quickAddPersonModal.email') }}</h5>
          <b-form-input
            id="quick-add-person-email"
            v-model="email"
            placeholder="Inserisci email"
            type="email"
          />
        </b-form-group>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
import {
  BRow, BCol, BModal, BFormGroup, BFormInput,
} from 'bootstrap-vue'

export default {
  components: {
    BModal,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
  },
  data() {
    return {
      name: '',
      surname: '',
      email: '',
    }
  },
  methods: {
    addPerson(bvModalEvt) {
      bvModalEvt.preventDefault()
      // aggiungere controllo dopo prevent default e prima di $nextTick
      this.$emit('emitQuickAddPerson', { name: `${this.name} ${this.surname}`, email: this.email })
      this.$nextTick(() => {
        this.$refs['quick-add-person-modal'].toggle('#toggle-btn')
      })
    },
  },
}
</script>

<style>

</style>

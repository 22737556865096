<template>
  <div>
    <b-sidebar
      id="sidebar-supplier"
      ref="sidebar-supplier"
      sidebar-class="sidebar-lg"
      bg-variant="white"
      backdrop-variant="dark"
      backdrop
      :no-close-on-backdrop="true"
      shadow
      no-header
      right
      @change="(val) => $emit('update:is-event-handler-sidebar-active', val)"
    >

      <template #default="{ hide }">
        <!-- Header -->
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h5 class="mb-0">
            {{ form.anagrafic.name !== '' ? 'Modifica Fornitore' : 'Aggiungi Fornitore' }}
          </h5>
          <div>
            <feather-icon
              v-if="form.anagrafic.name !== ''"
              icon="TrashIcon"
              class="cursor-pointer"
              @click="$emit('remove-customer', form); resetForm(); hide();"
            />
            <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              size="16"
              @click="resetForm(); hide()"
            />
          </div>
        </div>
        <form-wizard
          ref="wizard-customer-manage"
          color="#7367F0"
          :title="null"
          :subtitle="null"
          step-size="xs"
          finish-button-text="Salva"
          autocomplete="off"
          class="steps-transparent mb-3"
          @on-complete="formSubmitted"
        >
          <tab-content
            active
            :before-change="validationAnagrafic"
          >
            <validation-observer
              ref="customer-anagrafic-form-rules"
              tag="form"
            >
              <h4 class="mb-2">
                Anagrafica
              </h4>
              <b-container
                class="d-flex mt-2 mb-2"
                fluid
              >
                <!-- <span class="align-self-center">Chi stai registrando?:</span> -->
                <b-form-radio
                  v-model="typeSelected"
                  class="mr-2"
                  name="some-radios"
                  value="Person"
                >
                  <b>Persona Fisica</b>
                </b-form-radio>
                <b-form-radio
                  v-model="typeSelected"
                  class=""
                  name="some-radios"
                  value="Organization"
                > <b>Persona Giuridica</b>
                </b-form-radio>
              </b-container>
              <b-row no-gutters>
                <b-col>
                  <b-form-group
                    label="Inserisci Nome"
                    label-for="customer-first-name"
                    label-class="font-weight-bolder"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="nome"
                      rules="required"
                    >
                      <b-form-input
                        id="customer-first-name"
                        v-model="form.anagrafic.name"
                        :state="errors.length > 0 ? false:null"
                        placeholder="Nome"
                        autocomplete="nope"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                  <b-form-group
                    label="Inserisci Cognome"
                    label-for="customer-surname"
                    label-class="font-weight-bolder"
                  >
                    <b-form-input
                      id="customer-surname"
                      v-model="form.anagrafic.surname"
                      placeholder="Cognome"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col
                  cols="9"
                  class="mr-md-1"
                >
                  <b-form-group
                    label="Data di Nascita"
                    label-for="customer-bornInformation-date"
                    label-class="font-weight-bolder"
                  >
                    <b-form-datepicker
                      id="customer-bornInformation-date"
                      v-model="form.anagrafic.dateOfBorn"
                      :date-format-options="{ year: 'numeric', month: 'long', day: 'numeric' }"
                      class="mb-1"
                      locale="it"
                    />
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group
                    label="Sesso"
                    label-for="customer-gender"
                    label-class="font-weight-bolder"
                  >
                    <div
                      id="customer-gender"
                      class="demo-inline"
                    >
                      <b-form-radio
                        v-model="form.anagrafic.gender"
                        name="M"
                        value="M"
                      > M
                      </b-form-radio>
                      <b-form-radio
                        v-model="form.anagrafic.gender"
                        name="F"
                        value="F"
                      > F
                      </b-form-radio>
                    </div>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-form-group
                    label="Città"
                    label-for="customer-anagrafic-city-born"
                    label-class="font-weight-bolder"
                  >
                    <b-form-input
                      id="customer-anagrafic-city-born"
                      v-model="form.anagrafic.cityOfBorn"
                      placeholder="Città"
                    />
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group
                    label="Prov"
                    label-for="customer-anagrafic-prov"
                    label-class="font-weight-bolder"
                  >
                    <b-form-input
                      id="customer-anagrafic-prov"
                      v-model="form.anagrafic.provOfBorn"
                      placeholder="Prov."
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <label for="customer-anagrafic-fiscalCode"><b>Codice Fiscale</b></label>
                  <validation-provider
                    #default="{ errors }"
                    name="Codice Fiscale"
                    rules="required"
                  >
                    <b-input-group>
                      <b-form-input
                        id="customer-anagrafic-fiscalCode"
                        v-model="form.anagrafic.fiscalCode"
                        :state="errors.length > 0 ? false:null"
                        placeholder="Codice Fiscale"
                      />
                      <b-input-group-append>
                        <b-button
                          variant="primary"
                          @click="calculateFiscalCode()"
                        >
                          Calcola
                        </b-button>
                      </b-input-group-append>
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>
              </b-row>
              <div
                v-if="typeSelected === 'Organization'"
                no-gutters
              >
                <h4 class="mb-2 mt-2">
                  Anagrafica Fiscale
                </h4>
                <b-row>
                  <b-col
                    lg="6"
                  >
                    <b-form-group
                      label="Regione Sociale"
                      label-for="customer-organization-name"
                      label-class="font-weight-bolder"
                    >
                      <b-form-input
                        id="customer-organization-name"
                        v-model="form.organization.name"
                        placeholder="Nome"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col
                    lg="6"
                  >
                    <b-form-group
                      label="Partita Iva"
                      label-for="customer-organization-codeVat"
                      label-class="font-weight-bolder"
                    >
                      <b-form-input
                        id="customer-organization-codeVat"
                        v-model="form.organization.codeVat"
                        placeholder="Partita Iva"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col
                    lg="6"
                  >
                    <b-form-group
                      label="PEC aziendale"
                      label-for="customer-organization-email-pec"
                      label-class="font-weight-bolder"
                    >
                      <b-form-input
                        id="customer-organization-email-pec"
                        v-model="form.organization.emailPec"
                        placeholder="Email Pec Aziendale"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col
                    lg="6"
                  >
                    <b-form-group
                      label="Codice SDI"
                      label-for="customer-organization-codeSdi"
                      label-class="font-weight-bolder"
                    >
                      <b-form-input
                        id="customer-organization-codeSdi"
                        v-model="form.organization.codeSdi"
                        placeholder="Codice SDI"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col
                    lg="6"
                  >
                    <b-form-group
                      label="Email Principale"
                      label-for="customer-organization-email"
                      label-class="font-weight-bolder"
                    >
                      <b-form-input
                        id="customer-organization-email"
                        v-model="form.organization.emailMain"
                        placeholder="Email"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col
                    lg="6"
                  >
                    <b-row no-gutters>
                      <b-col>
                        <b-form-group
                          label="Email Secondaria"
                          label-for="customer-organization-emailSecondary"
                          label-class="font-weight-bolder"
                        >
                          <b-form-input
                            id="customer-organization-emailSecondary"
                            v-model="form.organization.emailSecondary"
                            placeholder="Email Secondaria"
                          />
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </b-col>
                  <b-col>
                    <b-row no-gutters>
                      <b-col>
                        <b-form-group
                          label="Checkbox"
                          label-for="customer-checkbox"
                          label-class="font-weight-bolder"
                        >
                          <b-form-checkbox-group
                            id="customer-checkbox"
                            v-model="form.organization.checkBoxOrganization"
                            :options="checkOptions"
                            value-field="value"
                            text-field="name"
                            disabled-field="notEnabled"
                          />
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </div>
            </validation-observer>
          </tab-content>

          <tab-content>
            <h4 class="mb-2">
              Indirizzi
            </h4>
            <b-row>
              <b-col>
                <b-form-group
                  label="Tipo"
                  label-for="typeAddress"
                  label-class="font-weight-bolder"
                >
                  <b-form-select
                    id="typeAddress"
                    ref="typeAddress"
                    v-model="addressSelected"
                    :options="options"
                  />
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group
                  label="Via/Piazza"
                  label-for="customer-anagrafic-address-street"
                  label-class="font-weight-bolder"
                >
                  <b-form-input
                    id="customer-anagrafic-address-street"
                    v-model="form.address.street"
                    autocomplete="off"
                    placeholder="Via/piazza"
                  />
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group
                  label="N.Civico"
                  label-for="customer-address-number"
                  label-class="font-weight-bolder"
                >
                  <b-form-input
                    id="customer-address-number"
                    v-model="form.address.number"
                    placeholder="Numero Civico"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group
                  label="Città"
                  label-for="customer-address-city"
                  label-class="font-weight-bolder"
                >
                  <b-form-input
                    id="customer-address-city"
                    v-model="form.address.city"
                    placeholder="Città"
                  />
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group
                  label="Prov"
                  label-for="customer-address-prov"
                  label-class="font-weight-bolder"
                >
                  <b-form-input
                    id="customer-address-prov"
                    v-model="form.address.prov"
                    placeholder="Prov."
                  />
                </b-form-group>
              </b-col>
              <b-col>
                <div class="overflow-auto">
                  <b-form-group
                    label="CAP"
                    label-for="customer-address-cap"
                    label-class="font-weight-bolder"
                  >
                    <b-form-input
                      id="customer-address-cap"
                      v-model="form.address.cap"
                      placeholder="CAP"
                    />
                  </b-form-group>
                </div>
              </b-col>
              <b-col
                cols="12"
              >
                <b-button
                  v-if="!selectedEdit"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  class="btn-icon"
                  @click="addAddress"
                >
                  <feather-icon icon="PlusIcon" />
                </b-button>
                <b-button
                  v-if="selectedEdit"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="success"
                  class="btn-icon"
                  @click="confirmEditAddress(form.address.id)"
                >
                  <feather-icon icon="CheckIcon" />
                </b-button>
              </b-col>
              <!-- <FormRepetearAddress /> -->
              <b-col cols="12">
                <hr>
              </b-col>
              <b-col cols="12">
                <div
                  v-if="address.length > 0"
                  class="mt-1 mb-1"
                >
                  <h3>Indirizzi Registrati</h3>
                  <div
                    v-for="(singleAddress, index) in address"
                    :key="index"
                    class="mb-1"
                  >
                    <b-row class="d-flex justify-content-between">
                      <b-col
                        cols="5"
                      >
                        <strong class="mr-1">Tipo:</strong>{{ singleAddress.type }}
                      </b-col>
                      <b-col
                        cols="6"
                        class="justify-content-between"
                      >
                        <div>
                          <strong class="mr-1">Via/Piazza:</strong>{{ singleAddress.street }}
                        </div>
                      </b-col>
                      <b-col
                        cols="1"
                      >
                        <b-dropdown
                          variant="link"
                          toggle-class="text-decoration-none p-0 align-self-end"
                          no-caret
                        >
                          <template v-slot:button-content>
                            <feather-icon
                              icon="MoreVerticalIcon"
                              size="16"
                              class="text-body align-middle mr-25"
                            />
                          </template>
                          <b-dropdown-item
                            @click="editAddress(singleAddress)"
                          >
                            <feather-icon
                              icon="Edit2Icon"
                              class="mr-50"
                            />
                            <span>Edit</span>
                          </b-dropdown-item>
                          <b-dropdown-item
                            @click="deleteAddress(singleAddress.id)"
                          >
                            <feather-icon
                              icon="TrashIcon"
                              class="mr-50"
                            />
                            <span>Delete</span>
                          </b-dropdown-item>
                        </b-dropdown>
                      </b-col>
                    </b-row>
                    <b-row class="d-flex justify-content-between">
                      <b-col
                        cols="6"
                      >
                        <strong class="mr-1">N.Civico:</strong>{{ singleAddress.number }}
                      </b-col>
                      <b-col
                        cols="6"
                      >
                        <strong class="mr-1">Città:</strong>{{ singleAddress.city }}
                      </b-col>
                    </b-row>
                    <b-row class="d-flex justify-content-between">
                      <b-col
                        cols="6"
                      >
                        <strong class="mr-1">Prov:</strong>{{ singleAddress.prov }}
                      </b-col>
                      <b-col
                        cols="6"
                      >
                        <strong class="mr-1">CAP:</strong>{{ singleAddress.cap }}
                      </b-col>
                    </b-row>
                  </div>
                </div>
              </b-col>
            </b-row>
          </tab-content>
          <tab-content>
            <h4 class="mb-2">
              Contatti
            </h4>
            <b-row>
              <b-col>
                <label for="customer-contact-personal-phone"><b>Numero Cellulare</b></label>
                <b-form-group>
                  <b-form-input
                    id="customer-contact-personal-phone"
                    v-model="form.contact.personalPhone"
                    placeholder="Numero Personale "
                  />
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                lg="6"
              >
                <label for="customer-contact-homePhone"><b>Numero Casa</b></label>
                <b-form-group>
                  <b-form-input
                    id="customer-contact-number"
                    v-model="form.contact.homePhone"
                    type="text"
                    placeholder="Numero Casa"
                  />
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                lg="6"
              >
                <label for="customer-contact-office"><b>Numero Ufficio</b></label>
                <b-form-group>
                  <b-form-input
                    id="customer-contact-office"
                    v-model="form.contact.officePhone"
                    placeholder="Numero Ufficio"
                  />
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                lg="6"
              >
                <label for="customer-contact-emailMain"><b>Email Principale</b></label>
                <b-form-group>
                  <b-form-input
                    id="customer-contact-emailMain"
                    v-model="form.contact.emailMain"
                    placeholder="Email Principale"
                  />
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                lg="6"
              >
                <label for="customer-contact-emailSecondary"><b>Email Secondaria</b></label>
                <b-form-group>
                  <b-form-input
                    id="customer-contact-emailSecondary"
                    v-model="form.contact.emailSecondary"
                    placeholder="Email Secondaria"
                  />
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                lg="6"
              >
                <label for="customer-contact-emailPec"><b>PEC personale</b></label>
                <b-form-group>
                  <b-form-input
                    id="customer-contact-emailPec"
                    v-model="form.contact.emailPec"
                    placeholder="PEC"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </tab-content>
          <tab-content>
            <h4 class="mb-2">
              GDPR
            </h4>
            <b-row>
              <b-col>
                <b-form-group
                  label="GDPR"
                  label-for="customer-gdpr-acconsent1"
                >
                  <b-form-checkbox
                    v-model="form.gdpr.acconsent1"
                    :value="true"
                    class="custom-control-primary"
                  >
                    Acconsento1
                  </b-form-checkbox>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group
                  label="VDR"
                  label-for="customer-address-street"
                >
                  <b-form-checkbox
                    v-model="form.gdpr.acconsent2"
                    :value="true"
                    class="custom-control-primary"
                  >
                    Acconsento2
                  </b-form-checkbox>
                </b-form-group>
              </b-col>
            </b-row>
          </tab-content>

          <!-- Form Actions -->
          <!-- <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              {{ 'Add ' }}
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="reset"
              variant="outline-secondary"
            >
              Reset
            </b-button>
          </div> -->
          <template
            slot="footer"
            slot-scope="props"
          >
            <div class="wizard-footer-left">
              <wizard-button
                v-if="props.activeTabIndex > 0"
                :style="props.fillButtonStyle"
                @click.native="props.prevTab()"
              >Indietro</wizard-button>
            </div>
            <div class="wizard-footer-right">
              <wizard-button
                v-if="!props.isLastStep"
                class=""
                :style="props.fillButtonStyle"
                @click.native="props.nextTab()"
              >{{ 'Successivo' }} </wizard-button>
              <wizard-button
                v-if="props.isLastStep && form.id > 0"
                class=""
                :style="props.fillButtonStyle"
                @click.native="editCustomer(); hide();"
              >{{ 'Modifica' }} </wizard-button>
              <wizard-button
                v-if="props.isLastStep && form.id < 0"
                class=""
                :style="props.fillButtonStyle"
                @click.native="addCustomer(); hide();"
              >{{ 'Salva' }} </wizard-button>
            </div>

          </template>
        </form-wizard>

      </template>

    </b-sidebar>
  </div>
</template>

<script>
import {
  BRow, BCol, BSidebar, BFormGroup, BFormInput, BFormCheckbox, BButton, BContainer, BFormRadio, BFormDatepicker, BInputGroupAppend, BInputGroup, BFormCheckboxGroup, BFormSelect, BDropdown, BDropdownItem,
} from 'bootstrap-vue'
// import flatPickr from 'vue-flatpickr-component'
import {
  FormWizard, TabContent, WizardButton,
} from 'vue-form-wizard'
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import Ripple from 'vue-ripple-directive'
import { required, email, url } from '@validations'
// import formValidation from '@core/comp-functions/forms/form-validation'
// import { ref, toRefs } from '@vue/composition-api'
// import useColllaboratorsEventHandler from './useColllaboratorsEventHandler'
// import FormRepetearAddress from './FormRepetearAddress.vue'

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BSidebar,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BFormRadio,
    BFormDatepicker,
    BContainer,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckboxGroup,
    // FormRepetearAddress,
    BFormSelect,

    BDropdown,
    BDropdownItem,

    FormWizard,
    TabContent,
    WizardButton,

    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  /* model: {
    prop: 'isEventHandlerSidebarActive',
    event: 'update:is-event-handler-sidebar-active',
  }, */
  props: {
  },
  data() {
    return {
      required,
      email,
      url,
      locale: 'it',
      typeSelected: 'Person',
      selectedEdit: false,
      formReset: {
        id: -1,
        type: this.typeSelected,
        anagrafic: {
          name: '',
          surname: '',
          gender: '',
          dateOfBorn: '',
          provOfBorn: '',
          cityOfBorn: '',
          fiscalCode: '',
        },
        address: {
          id: '',
          type: this.addressSelected,
          street: '',
          number: '',
          city: '',
          prov: '',
          cap: '',
        },
        organization: {
          name: '',
          codeVat: '',
          emailPec: '',
          emailMain: '',
          emailSecondary: '',
          codeSdi: '',
          codeRa: '',
          checkBoxOrganization: [],
        },
        contact: {
          officePhone: '',
          personalPhone: '',
          homePhone: '',
          emailMain: '',
          emailSecondary: '',
          emailPec: '',
        },
        gdpr: {
          acconsent1: false,
          acconsent2: false,
        },
      },
      form: {
        id: -1,
        type: 'Person',
        anagrafic: {
          name: '',
          surname: '',
          gender: '',
          dateOfBorn: '',
          provOfBorn: '',
          cityOfBorn: '',
          fiscalCode: '',
        },
        address: {
          id: '',
          type: this.addressSelected,
          street: '',
          number: '',
          city: '',
          prov: '',
          cap: '',
        },
        organization: {
          name: '',
          codeVat: '',
          emailPec: '',
          emailMain: '',
          emailSecondary: '',
          codeSdi: '',
          codeRa: '',
          checkBoxOrganization: [],
        },
        contact: {
          officePhone: '',
          personalPhone: '',
          homePhone: '',
          emailMain: '',
          emailSecondary: '',
          emailPec: '',
        },
        gdpr: {
          acconsent1: false,
          acconsent2: false,
        },
      },
      addressSelected: null,
      address: [
        /* {
          id: '',
          type: '',
          street: '',
          number: '',
          city: '',
          prov: '',
          cap: '',
        }, */
      ],
      checkOptions: [
        { value: 'RA', name: 'RA' },
        { value: 'Altro', name: 'Altro' },
      ],
      options: [
        { value: 'Residenza', text: 'Residenza', selected: true },
        { value: 'Fatturazione', text: 'Fatturazione' },
        { value: 'Ufficio', text: 'Ufficio' },
        { value: 'Spedizione', text: 'Spedizione' },
      ],
    }
  },
  watch: {
    typeSelected() {
      // console.log('sono dentro watch')
      // if (this.typeSelected !== 'Person') {
      // console.log(this.$refs['wizard-customers-manage'])
      // this.$refs['wizard-customers-manage'].activateAll()
      // }
    },
  },
  mounted() {
    this.$refs['wizard-customer-manage'].activateAll()
    localize(this.locale)
  },
  methods: {
    setFormToEdit(value) {
      // console.log('setForm', value)
      // console.log('this.formReset', this.formReset)
      this.form.anagrafic.name = value.fullName
      this.form.id = value.id
      if (value.type === 'Person') {
        this.form.type = value.type
        this.form.anagrafic.name = value.fullName
        this.form.anagrafic.fiscalCode = value.fiscalCodeOrIva
        this.form.contact.emailMain = value.emailMain
        this.form.contact.personalPhone = value.personalPhone
      } else if (value.type === 'Organization') {
        this.form.type = value.type
        this.form.organization.name = value.fullName
        this.form.contact.emailMain = value.emailMain
        this.form.anagrafic.fiscalCode = value.fiscalCodeOrIva
      }
    },
    resetForm() {
      // console.log(this.$refs['wizard-customers-manage'])
      // console.log('prima', this.form)
      // console.log('prima formReset', this.formReset)
      this.form = JSON.parse(JSON.stringify(this.formReset))
      // console.log('dopo', this.form)
      // console.log('dopo formReset', this.formReset)
      setTimeout(() => {
        this.$refs['wizard-customer-manage'].reset()
        this.$refs['wizard-customer-manage'].activateAll()
        this.$refs['customer-anagrafic-form-rules'].reset()
      }, 350)
    },
    formSubmitted() {},
    editCustomer() {
      this.$emit('edit-customer', this.form)
      this.resetForm()
      // console.log('editCollaborators', this.form)
    },
    addCustomer() {
      this.$emit('add-customer', this.form)
      // console.log(this.form)
      this.resetForm()
    },
    addAddress() {
      const addressToAdd = {
        id: this.address.length + 1,
        type: this.addressSelected,
        street: this.form.address.street,
        number: this.form.address.number,
        city: this.form.address.city,
        prov: this.form.address.prov,
        cap: this.form.address.cap,
      }
      this.address.push(addressToAdd)
      this.form.address = this.formReset.address
    },
    editAddress(singleAddress) {
      this.selectedEdit = true
      this.addressSelected = singleAddress.type
      this.form.address.id = singleAddress.id
      this.form.address.street = singleAddress.street
      this.form.address.number = singleAddress.number
      this.form.address.city = singleAddress.city
      this.form.address.prov = singleAddress.prov
      this.form.address.cap = singleAddress.cap
    },
    confirmEditAddress(id) {
      const index = this.address.findIndex(r => r.id === id)
      this.address[index].type = this.addressSelected
      this.address[index].street = this.form.address.street
      this.address[index].number = this.form.address.number
      this.address[index].city = this.form.address.city
      this.address[index].prov = this.form.address.prov
      this.address[index].cap = this.form.address.cap
      this.selectedEdit = false
      // this.addressSelected = null
      this.form.address = this.formReset.address
    },
    deleteAddress(id) {
      const index = this.address.findIndex(r => r.id === id)
      this.address.splice(index, 1)
      this.selectedEdit = false
      // console.log(this.address)
    },
    validationAnagrafic() {
      return new Promise((resolve, reject) => {
        this.$refs['customer-anagrafic-form-rules'].validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
<style scoped>
.wizard-tab-container{
  min-height: 500px !important;
}
</style>

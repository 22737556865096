<template>
  <div>

    <b-card
      class="mb-0 pb-0"
      style="box-shadown:none"
    >
      <b-row
        no-gutters
      >
        <b-col
          cols="5"
        >
          <div class="">
            <div class="d-flex align-items-start">
              <div class="profile-img">
                <b-avatar
                  src="/img/1.9cba4a79.png"
                  variant="light-primary"
                  rounded
                  size="100px"
                  class=""
                />
              </div>
              <!-- profile title -->
              <div class="ml-2">
                <h2 class="">
                  {{ userData.fullName }}
                </h2>
                <p>{{ userData.emailMain }}</p>
                <div>
                  <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    v-b-toggle.sidebar-supplier
                    size="sm"
                    class="ml-auto"
                    variant="outline-primary"
                    @click="prepareSidebarToEdit(userData)"
                  >
                    <feather-icon
                      icon="EditIcon"
                      class="d-block d-md-none"
                    />
                    <span class="font-weight-bold d-none d-md-block">Modifica</span>
                  </b-button>
                </div>
              </div>
              <!--/ profile title -->
            </div>
          </div>
        </b-col>
        <b-col>
          <div
            class="m-2 d-none d-lg-block"
          >
            <SupplierGeneralStats :data="statisticsItems" />
          </div>
        </b-col>
      </b-row>
    </b-card>
    <b-tabs
      pills
      nav-class="nav-details-information-background-white"
    >

      <!-- general tab -->
      <b-tab
        active
      >

        <!-- title -->
        <template #title>
          <span class="font-weight-bold">Informazioni</span>
        </template>

        <Information
          v-if="userData"
          :user-data="userData"
        />
      </b-tab>
      <!--/ general tab -->

      <!-- change password tab -->
      <b-tab>

        <!-- title -->
        <template #title>
          <span class="font-weight-bold">Storico Interazioni</span>
        </template>

        <InteractionsStoric />
      </b-tab>
      <!--/ change password tab -->

      <!-- info -->
      <b-tab>

        <!-- title -->
        <template #title>
          <span class="font-weight-bold">Documenti</span>
        </template>

        <Documents />
      </b-tab>

      <!-- social links -->
      <b-tab>

        <!-- title -->
        <template #title>
          <span class="font-weight-bold">Pazienti</span>
        </template>

        <b-card>sono su pazienti</b-card>
      </b-tab>

      <!-- notification -->
      <b-tab
        lazy
      >

        <!-- title -->
        <template #title>
          <span class="font-weight-bold">Statistiche</span>
        </template>

        <SupplierStats />
      </b-tab>
    </b-tabs>
    <supplier-event-handler
      ref="event-handler-supplier"
      @remove-supplier="removeSupplier"
      @edit-supplier="editSupplier"
      @add-supplier="addSupplier"
    />
  </div>

</template>

<script>
import {
  BRow, BCol, BTabs, BTab, BCard, BAvatar, BButton, VBModal, VBToggle,
} from 'bootstrap-vue'
import { ref, onUnmounted } from '@vue/composition-api'

import Ripple from 'vue-ripple-directive'

// import router from '@/router'
import store from '@/store'
import SupplierEventHandler from '../supplierEventHandler/SupplierEventHandler.vue'
import Information from './tabs/Information.vue'
import Documents from './tabs/Documents.vue'
import InteractionsStoric from './tabs/InteractionsStoric.vue'
import SupplierStats from './tabs/SupplierStats.vue'
import SupplierGeneralStats from './tabs/SupplierGeneralStats.vue'

// import userStoreModule from '../userStoreModule'
// import CustomerViewTabSocial from './CustomerViewTabSocial.vue'

export default {
  components: {
    BCard,
    BTabs,
    BTab,
    BRow,
    BCol,
    BButton,
    BAvatar,
    Information,
    Documents,
    SupplierStats,
    InteractionsStoric,
    SupplierEventHandler,
    SupplierGeneralStats,
  },
  directives: {
    'b-modal': VBModal,
    'b-toggle': VBToggle,
    Ripple,
  },
  data() {
    return {
      statisticsItems: [
        {
          icon: 'TrendingUpIcon',
          color: 'light-primary',
          title: '4',
          subtitle: 'Pazienti',
          customClass: 'mb-2 mb-xl-0',
        },
        {
          icon: 'UserIcon',
          color: 'light-info',
          title: '15',
          subtitle: 'Visite',
          customClass: 'mb-2 mb-xl-0',
        },
        {
          icon: 'BoxIcon',
          color: 'light-danger',
          title: '10',
          subtitle: 'Analisi',
          customClass: 'mb-2 mb-sm-0',
        },
        {
          icon: 'DollarSignIcon',
          color: 'light-success',
          title: '6',
          subtitle: 'Vaccinazioni',
          customClass: '',
        },
      ],
    }
  },
  methods: {
    removeSupplier() {},
    editSupplier() {},
    addSupplier() {},
    prepareSidebarToEdit(user) {
      this.$refs['event-handler-supplier'].setFormToEdit(user)
    },
  },
  setup() {
    let userData = ref(null)
    userData = {
      // eslint-disable-next-line global-require
      coverImg: require('@/assets/images/profile/user-uploads/timeline.jpg'),
      emailMain: 'kocrevy0@thetimes.co.uk',
      avatar: '/img/9.3fd39137.png',
      type: 'Person',
      fullName: 'Korrie OCrevy',
      username: 'Kocrevy',
      status: 'active',
      country: 'italy',
      email: 'kocrevy0@thetimes.co.uk',
      dateBorn: '10/12/1990',
      cityBorn: 'Catania',
      streetAddress: 'Via entea',
      cityAddress: 'Mascalucia',
      addressInvoices: 'Via etnea 120',
      addressShipping: 'Via etnea 123 A',
      phonePersonal: '3489502145',
      phoneHome: '095988510',
      phoneOffice: '095895623',
      fiscalCodeOrIva: 'QPFPDP74S29C030E',
      fiscalCode: 'QPFPDP74S29C030E',
      privacyAccepted: 'Accettato',
      gdpr: 'Accettato',
      role: 'Editor',
      id: 1,
      originalIndex: 0,
      contact: '3387415011',
      rating: 4,
    }

    const USER_APP_STORE_MODULE_NAME = 'app-user'

    const selectedNav = true
    // Register module
    // if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })
    return {
      userData,
      selectedNav,
    }
  },
}
</script>

<style lang="scss" >
@import '@core/scss/vue/pages/page-profile.scss';

.no-bg{
  background-color: transparent;
}
.padding-customer-navbar{
  padding-bottom: 0.5rem;
}

</style>
<style scoped>
.card .card-body{
  padding-bottom: 0;
  background-color: white !important;
}
.card {
  box-shadow: none;
}
</style>

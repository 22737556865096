import { render, staticRenderFns } from "./QuickAddPerson.vue?vue&type=template&id=bfbbd44e&"
import script from "./QuickAddPerson.vue?vue&type=script&lang=js&"
export * from "./QuickAddPerson.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports